import React from 'react';

import { Row, Col } from 'antd';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import './BookingForm.scss';

export interface Props {
	isVehicle: boolean;
	location: Location;
}

export const BookingForm = (props: Props) => {
	const isVehicle = props.isVehicle;
	return (
		<div>
			{isVehicle ? (
				<div className="c_booking__wrap">
					<Row type="flex" className="c_booking c_booking--vehicle">
						<Col className="bg-dblue" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<h3>Book Now</h3>
							<p>Select your desired dates and times and select a vehicle from below</p>
							<form autoComplete="off">
								<Row type="flex" className="align-center">
									<input name="vtype_ca" type="hidden" value="van"/>
									<Col className="c_booking__col" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
										<div className="form-group">
											<label>Collection Date</label>
											<input type="date" min={new Date().toISOString().slice(0, 10)}/>
										</div>
										<div className="form-group">
											<label>Collection Time</label>
											<select className="form-control" name="collecttime_ca" id="collecttime_ca">
												<option value="8:00">8am</option>
												<option value="9:00">9am</option>
												<option value="10:00">10am</option>
												<option value="11:00">11am</option>
												<option value="12:00">12pm</option>
												<option value="13:00">1pm</option>
												<option value="14:00">2pm</option>
												<option value="15:00">3pm</option>
												<option value="16:00">4pm</option>
												<option value="17:00">5pm</option>
											</select>
										</div>
									</Col>
									<Col className="c_booking__col" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
										<div className="form-group">
											<label>Return Date</label>
											<input type="date"/>
										</div>
										<div className="form-group">
											<label>Return Time</label>
											<select className="form-control" name="returntime_ca" id="returntime_ca">
												<option value="8:00">8am</option>
												<option value="9:00">9am</option>
												<option value="10:00">10am</option>
												<option value="11:00">11am</option>
												<option value="12:00">12pm</option>
												<option value="13:00">1pm</option>
												<option value="14:00">2pm</option>
												<option value="15:00">3pm</option>
												<option value="16:00">4pm</option>
												<option value="17:00">5pm</option>
											</select>
										</div>
									</Col>
									<Col className="display-flex align-center" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<div className="form-group w-100">
											<button className="btn btn-secondary" type="submit">
												<FontAwesomeIcon icon={faSearch}/>Search Availability
											</button>
										</div>
									</Col>
								</Row>
							</form>
						</Col>
					</Row>
				</div>
			) : (
				<Row type="flex" className="c_booking">
					<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
						<h3>Book a Vehicle now</h3>
						<form autoComplete="off">
							<Row type="flex" className="align-center">
								<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
									<div className="form-group">
										<label>I would like...</label>
										<select>
											<option>Car</option>
											<option>Van</option>
										</select>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
									<div className="form-group">
										<label>From...</label>
										<input type="date" min={new Date().toISOString().slice(0, 10)}/>
									</div>
									<div className="form-group">
										<select className="form-control" name="collecttime_ca" id="collecttime_ca">
											<option value="8:00">8am</option>
											<option value="9:00">9am</option>
											<option value="10:00">10am</option>
											<option value="11:00">11am</option>
											<option value="12:00">12pm</option>
											<option value="13:00">1pm</option>
											<option value="14:00">2pm</option>
											<option value="15:00">3pm</option>
											<option value="16:00">4pm</option>
											<option value="17:00">5pm</option>
										</select>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
									<div className="form-group">
										<label>Until...</label>
										<input type="date"/>
									</div>
									<div className="form-group">
										<select className="form-control" name="returntime_ca" id="returntime_ca">
											<option value="8:00">8am</option>
											<option value="9:00">9am</option>
											<option value="10:00">10am</option>
											<option value="11:00">11am</option>
											<option value="12:00">12pm</option>
											<option value="13:00">1pm</option>
											<option value="14:00">2pm</option>
											<option value="15:00">3pm</option>
											<option value="16:00">4pm</option>
											<option value="17:00">5pm</option>
										</select>
									</div>
								</Col>
								<Col className="display-flex align-center" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
									<div className="form-group w-100">
										<button className="btn btn-secondary" type="submit">
											<FontAwesomeIcon icon={faSearch}/>Search our Vehicles
										</button>
									</div>
								</Col>
							</Row>
						</form>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default BookingForm;
