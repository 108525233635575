import React from 'react';

import { Row, Col } from 'antd';

import {HeroBanner} from '../../contracts/page';

import {GatsbyImage} from 'gatsby-plugin-image';

import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import './HeroBanner.scss';

export interface Props {
	heroBanner: HeroBanner;
	fluid: IGatsbyImageData;
	location: Location;
}

export const Hero = (props: Props) => {
	const heroBanner = props.heroBanner;
	const fluid      = props.fluid;
	const textColour = heroBanner.bannerTextColour;
	const bgColour   = heroBanner.bannerTextColour === 'white' ? 'black' : 'grey';
	return (
		<div>
			{(heroBanner.bannerLayout === 'image-right') ? (
				<Row type="flex" className="c_hero-banner mb-0 full-width-row">
					<Col className={'md-order-2 c_hero-banner__contentWrap bg-' + bgColour} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
						<div className="c_hero-banner__content">
							<h1 className={'text-' + textColour}>{heroBanner.bannerTitle}</h1>
							<div className={'text-' + textColour} dangerouslySetInnerHTML={{__html: heroBanner.bannerContent}}/>
						</div>
					</Col>
					<Col className="md-order-1" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
						<GatsbyImage loading={'eager'} image={fluid} alt={heroBanner.bannerTitle} title={heroBanner.bannerTitle} />
					</Col>
				</Row>
			) : ''}
			{(heroBanner.bannerLayout === 'image-left') ? (
				<Row type="flex" className="c_hero-banner mb-0 full-width-row">
					<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
						<GatsbyImage loading={'eager'} image={fluid} alt={heroBanner.bannerTitle} title={heroBanner.bannerTitle} />
					</Col>
					<Col className={'c_hero-banner__contentWrap bg-' + bgColour} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
						<div className="c_hero-banner__content">
							<h1 className={'text-' + textColour}>{heroBanner.bannerTitle}</h1>
							<div className={'text-' + textColour} dangerouslySetInnerHTML={{__html: heroBanner.bannerContent}}/>
						</div>
					</Col>
				</Row>
			) : ''}
			{(heroBanner.bannerLayout === 'full-width') ? (
				<Row type="flex" className="c_hero-banner c_hero-banner--full-width full-width-row">
					<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
						<GatsbyImage loading={'eager'} image={fluid} alt={heroBanner.bannerTitle} title={heroBanner.bannerTitle} />
						<div className="c_hero-banner__content">
							<h1 className={'text-' + textColour}>{heroBanner.bannerTitle}</h1>
							<div className={'text-' + textColour} dangerouslySetInnerHTML={{__html: heroBanner.bannerContent}}/>
						</div>
					</Col>
				</Row>
			) : ''}
		</div>
	);
};

export default Hero;
