import React from 'react';

import {graphql} from 'gatsby';

import { Row, Col } from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import {Page} from '../contracts/page';
import {WpSettings} from '../contracts/util';

import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import BookingForm from '../components/Booking';
import ContentBlockHtml from '../components/ContentBlock';
import EnquireForm from '../components/Enquire';
import FaqBlock from '../components/Faqs';
import Hero from '../components/Hero';
import ReviewsBlock from '../components/Reviews';

import '../styles/contact.scss';

export interface Props {
	data: {
		wpPage: Page;
		wp: WpSettings;
	};
	location: Location;
}

export const ContactPage = (props: Props) => {
	const page = props.data.wpPage;
	const wp   = props.data.wp;
	const fluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.heroBanner &&
		page.heroBanner.bannerImage &&
		page.heroBanner.bannerImage.localFile &&
		page.heroBanner.bannerImage.localFile.childImageSharp &&
		page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData
	) ? page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData : null;
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true
				}
			}
		]
	};
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area">
					<div className="home">
						<div className="top-banner">
							{(page.heroBanner && page.heroBanner.showBanner === 'yes' && fluid !== null) && (
								<Hero location={props.location} heroBanner={page.heroBanner} fluid={fluid}/>
							)}
							{(page.bookNowBlock && page.bookNowBlock.showBookNowBlock === 'yes') ? (
								<BookingForm location={props.location} isVehicle={true}/>
							) : ''}
						</div>
						<Row type="flex" className="c_contact page-gutter">
							<Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
								<div className="c_contact__form">
									<EnquireForm location={props.location} simple={true}/>
								</div>
							</Col>
							<Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
								<div className="c_contact__content">
									<a className="mediahawkNumber6089 mhMobile" href={'tel:' + wp.cgvSettings.telephone}>
										<img alt="Telephone" src="../../images/ui/phone.svg"/>
										{wp.cgvSettings.telephone}
									</a>
									<a href={'mailto:' + wp.cgvSettings.emailAddress}>
										<img alt="Email" src="../../images/ui/email.svg"/>
										{wp.cgvSettings.emailAddress}
									</a>
									<p>
										<img alt="Address" src="../../images/ui/location-pin.svg"/>
										<span>
											Dack Motor Group, {wp.cgvSettings.address1}, {wp.cgvSettings.address2}, {wp.cgvSettings.address3}, {wp.cgvSettings.address4}
										</span>
									</p>
								</div>
							</Col>
						</Row>
						<Row type="flex" className="c_contact__map page-gutter">
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<iframe frameBorder="0" scrolling="no"
										src="https://maps.google.com/maps?q=lincoln%20vehicle%20hire%2C%20Lincoln%20Fields%20Business%20Park%2C%20Whisby%20Rd%2C%20Lincoln%20LN6%203QW&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
										title="lincoln vehicle hire, Lincoln Fields Business Park, Whisby Rd, Lincoln LN6 3QW"
										aria-label="lincoln vehicle hire, Lincoln Fields Business Park, Whisby Rd, Lincoln LN6 3QW"/>
							</Col>
						</Row>
						{(page.reviews && page.reviews.showReviews === 'yes') ? (
							<ReviewsBlock location={props.location} reviews={page.reviews} settings={settings}/>
						) : ''}
						{(page.faqs && page.faqs.showFaqs === 'yes') ? (
							<FaqBlock location={props.location} showHeading={true} faqs={page.faqs} bgWhite={false}/>
						) : ''}
						{(page.contentBlock && page.contentBlock.showContentBlock === 'yes') ? (
							<ContentBlockHtml location={props.location} contentBlock={page.contentBlock}/>
						) : ''}
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default ContactPage;

export const query = graphql`
	query($id: String!) {
	  wp {
	    cgvSettings {
		  address1
		  address2
		  address3
		  address4
		  emailAddress
		  telephone
		}
	  }
      wpPage(id: { eq: $id }) {
      	title
		content
		slug
		id
        bookNowBlock {
          showBookNowBlock
        }
		bannerContentBlock {
		  bannerContentBackgroundColour
		  bannerContentContent
		  bannerContentCtaLink
		  bannerContentCtaText
		  bannerContentShowCta
		  bannerContentSubtitle
		  bannerContentSubtitleColour
		  bannerContentTitle
		  bannerContentTitleColour
		  fieldGroupName
		  showBannerContentBlock
		  bannerContentImage {
		    altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		contentBlock {
		  contentContent
		  contentSubtitle
		  contentTitle
		  fieldGroupName
		  showContentBlock
		}
		ctas {
		  ctas {
			fieldGroupName
			ctaTitle
			ctaIcon {
			  altText
			  localFile {
			    publicURL
			    childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
			    }
			  }
		    }
			ctaLink {
			  target
			  title
			  url
			}
		  }
		  fieldGroupName
		}
		faqs {
		  faqs {
			question
			fieldGroupName
			answer
		  }
		  showFaqs
		  fieldGroupName
		}
		heroBanner {
		  bannerContent
		  bannerLayout
		  bannerTextColour
		  fieldGroupName
		  bannerTitle
		  showBanner
		  bannerImage {
			altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		hireTypes {
		  fieldGroupName
		  hireType {
			fieldGroupName
			hireContent
			typeOfHire
			hireLink {
			  ... on WpPage {
				link
			  }
			}
			hireImage {
			  altText
			  caption
			  description
			  fileSize
			  localFile {
				childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
				}
			  }
			}
		  }
		}
		reviews {
		  showReviews
		  reviews {
			reviewContent
			reviewName
			reviewStars
		  }
		}
		seo {
		  metaDesc
		  metaKeywords
		  metaRobotsNoindex
		  metaRobotsNofollow
		  opengraphAuthor
		  opengraphDescription
		  opengraphModifiedTime
		  opengraphPublishedTime
		  opengraphPublisher
		  opengraphSiteName
		  opengraphTitle
		  opengraphType
		  opengraphUrl
		  readingTime
		  title
		  twitterTitle
		  schema {
			articleType
			pageType
			raw
		  }
		  twitterDescription
		  twitterImage {
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
      }
    }
`;
